<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.month')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.month"
                                :options="monthList"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Division"  vid="division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.division')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.division_id"
                                :options="divisionList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="District"  vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.district')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Name"  vid="fertilizer_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.fertilizerName')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="id ? true: false"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <div class="col text-right" v-if="!id">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                    </b-row>
                    <br><br>
                    <b-table-simple class="mt-3" hover bordered small caption-top responsive v-if="searchData">
                      <b-thead head-variant="secondary">
                          <b-tr>
                              <b-th colspan="4" class="text-center">{{ $t('fertilizerSales.ministryAmount') + ' : ' }} {{ ministry_allotment }}</b-th>
                              <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('fertilizerSales.badcAllotment') + ' : ' }} {{ badc_allotment }}</b-th>
                          </b-tr>
                          <b-tr>
                              <b-th colspan="1" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th :colspan="fertilizerNameList.length" class="text-center">{{ $t('fertilizerSales.dealerName') }}</b-th>
                              <b-th class="text-center">{{ $t('fertilizerSales.quantity') }}</b-th>
                          </b-tr>
                          <b-tr v-for="(dealer, index) in dealerList" :key="index" >
                              <b-th colspan="1" class="text-center">{{ $n(index + 1) }}</b-th>
                              <b-th :colspan="fertilizerNameList.length" class="text-center">{{ currentLocale === 'en' ? dealer.name : dealer.name_bn }}</b-th>
                              <b-th class="text-center"><b-form-input
                                  type="text"
                                  :id="'dealer_' + (index+1)"
                                  v-model="formData.dealerInfo[index].quantity"
                                  @keyup="getValidity"
                              ></b-form-input></b-th>
                          </b-tr>
                        <b-tr>
                            <b-th colspan="4" class="text-center">{{ $t('fertilizerSales.remaining') }}</b-th>
                            <b-th :colspan="fertilizerNameList.length" class="text-center"><b-form-input
                                  type="text"
                                  readonly
                                  v-model="remaining"
                              ></b-form-input></b-th>
                        </b-tr>
                      </b-thead>
                    </b-table-simple>
                    <b-row v-if="searchData">
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="memo_no"
                          >
                            <template v-slot:label>
                              {{$t('fertilizerSales.memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="memo_no"
                                v-model="formData.memo_no"
                            ></b-form-input>
                            <div class="invalid-feedback" style="display:block"  v-if="errorLists && errorLists.memo_no">
                              {{errorLists.memo_no[0]}}
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="allocation_date"
                          >
                            <template v-slot:label>
                              {{$t('movement.allocationDate')}} <span class="text-danger">*</span>
                            </template>
                            <flat-pickr class="form-control"
                              v-model="formData.allocation_date"
                              :placeholder="$t('globalTrans.select_date')"
                            ></flat-pickr>
                            <div class="invalid-feedback" style="display:block"  v-if="errorLists && errorLists.allocation_date">
                              {{errorLists.allocation_date[0]}}
                            </div>
                          </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right" v-if="searchData">
                        <b-button type="submit"  @click="submitData" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerAllocationStore, dealerAllocationUpdate, getAllocationInfo } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.searchData = true
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.createData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        district_id: 0,
        division_id: 0,
        fertilizer_id: 0,
        allocation_date: '',
        dealerInfo: [],
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        memo_no: ''
      },
      index: '',
      dealerList: [],
      remaining: 0,
      ministry_allotment: '',
      badc_allotment: '',
      searchData: false,
      ministry: [],
      errorLists: {
      },
      districtList: []
    }
  },
  computed: {
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    allocationTypeList: function () {
      const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
      const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
          } else {
          return { text: item.text_en, value: item.value }
          }
      })
      return tempData
    },
    monthList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'formData.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    }
  },
  methods: {
    async createData () {
      RestApi.getData(seedFertilizerServiceBaseUrl, getAllocationInfo, this.formData).then(response => {
        if (response.success) {
            flatpickr('#from_date', {})
            this.searchData = true
            this.ministry_allotment = response.ministry_allocation_amount
            this.badc_allotment = response.badc_allocation_amount
            this.dealerList = response.dealer_list
            var dealerData = []
            if (this.id) {
              this.formData.dealer_allocation_details.forEach((dealer, dealerIndex) => {
                const item = {
                    dealer_id: dealer.dealer_id,
                    dealer_allo_details_id: dealer.id,
                    dealer_allocations_id: dealer.dealer_allocations_id,
                    quantity: dealer.quantity ? dealer.quantity : 0
                }
                dealerData.push(item)
              })
            } else {
              this.dealerList.forEach((dealer, dealerIndex) => {
                const item = {
                    dealer_id: dealer.id,
                    dealer_allo_details_id: '',
                    dealer_name: this.$i18n.locale === 'en' ? dealer.name : dealer.name_bn,
                    quantity: ''
                }
                dealerData.push(item)
              })
            }
          this.formData.dealerInfo = dealerData
        } else {
          this.$toast.error({
            title: 'Error',
            message: response.message,
            color: '#D6E09B'
          })
        }
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async submitData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${dealerAllocationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, dealerAllocationStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.errorLists = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getValidity (event) {
      var total = 0
      this.formData.dealerInfo.forEach((dealer, index) => {
        if (dealer.quantity) {
          total += parseInt(dealer.quantity)
        }
      })
      this.remaining = this.badc_allotment - total
      if (this.remaining < 0) {
        const currentId = '#' + event.target.id
        const idValAmount = document.querySelector(currentId).value
        const remainingIdVal = Math.floor(idValAmount / 10)
        document.querySelector(currentId).value = remainingIdVal
        this.remaining = this.badc_allotment - ((total - idValAmount) + remainingIdVal)
      }
    }
  }
}
</script>
